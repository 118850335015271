<template>
<span >
    Từ {{ from }} -> {{to}} trong tổng {{ pagination.total }}
</span>
</template>

<script>
export default {
    name: 'table-info',
    props: {
        pagination: Object,
    },
    computed: {
        from() {
            return this.pagination.total ? (this.pagination.page - 1) * this.pagination.limit + 1 : 0;
        },
        to() {
            return this.pagination.total < this.pagination.page * this.pagination.limit ? this.pagination.total : this.pagination.page * this.pagination.limit;
        },
    },
    mounted() {}
};
</script>
