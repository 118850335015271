<template>
    <div>
        <address-filter :reset="reset" @reset="setting_reset" />
        <div class="row">
            <div class="col-lg-7">
                <div class="row">
                    <div class="col-lg-4">
                        <b-form-group>
                            <input type="text" class="form-control" v-model="search"
                                placeholder="Nhập tên người dùng" />
                        </b-form-group>
                    </div>
                    <div class="col-lg-4" style="margin-bottom: 15px;">
                        <b-form-select v-model="verify" :options="options" aria-placeholder="a" style="cursor: pointer">
                        </b-form-select>
                    </div>
                    <div class="col-lg-4">
                        <b-button variant="light" class="btn-refresh" @click="refresh">
                            <i class="fas fa-sync"></i>
                        </b-button>
                    </div>
                </div>
            </div>
            <div class="col-lg-5">

            </div>
        </div>
    </div>
</template>

<script>
import addressFilter from '../../../../components/filter/address-filter.vue';

export default {
    name: 'product-filter',
    components: {
        addressFilter,
    },
    data() {
        return {
            verify: null,
            search: "",
            reset: false,
            options: [
                { value: null, disabled: true, text: 'Trạng thái xác thực' },
                { value: 0, disabled: false, text: 'Chưa xác thực' },
                { value: 1, disabled: false, text: 'Đã xác thực' },
                { value: 2, disabled: false, text: 'Chờ xác thực' }
            ]
        };
    },
    watch: {
        search: {
            handler: function () {
                let query = {
                    ...this.$route.query,
                };
                if (this.search == '') {
                    delete query.search
                    this.$router.push({
                        name: this.$route.name,
                        query: query,
                    });
                }
                if (this.search.length > 0) {
                    query.search = this.search;
                    setTimeout(() => {
                        if (query.search == this.search || (this.search.length < query.search.length && query.search.length == this.search.length)) {
                            this.$router.push({
                                name: this.$route.name,
                                query: query,
                            });
                        }
                    }, 800);
                } else {
                    delete query.search;
                }
            },
            deep: true,
        },
        verify: {
            handler: function () {
                let query = {
                    ...this.$route.query
                };
                if (this.verify != null) {
                    query.verify = this.verify;
                } else {
                    delete (query.verify);
                }
                this.$router.push({
                    name: this.$route.name,
                    query: query
                });
            },
            deep: true,
        },
    },
    methods: {
        setting_reset() {
            this.reset = false
        },
        async refresh() {
            Object.assign(this.$data, this.$options.data.apply(this))
            this.reset = true
        },
        getData() {
            let query = this.$route.query
            if (query.search) {
                this.search = query.search
            }
            if (query.verify !== "" && query.verify != null) {
                this.verify = query.verify
            }
        }
    },
    created() {
        this.getData()
    },
};
</script>

<style scoped>
/* .style-width {
    width: 600px !important;
} */
</style>
