<template>
<div :class="['row', 'chart', {'style-width':check_page==true}]" >
    <b-col v-if="role == ADMIN" class="mb-2 style-padding" lg="4" md="12">
        <b-form-select class="style-filter" v-model="province_name" :options="provinces">
            <template #first>
                <b-form-select-option :value="null" disabled >Chọn Tỉnh</b-form-select-option>
            </template>
        </b-form-select>
    </b-col>
    <b-col v-if="role == ADMIN || role == PROVINCE_OFFICIAL" class="mb-2 style-padding" lg="4" md="12">
        <b-form-select class="style-filter" v-model="district_name" :options="districts">
            <template #first>
                <b-form-select-option :value="null" disabled >Chọn Quận/Huyện</b-form-select-option>
            </template>
        </b-form-select>
    </b-col>
    <b-col v-if="role == ADMIN || role == PROVINCE_OFFICIAL || role == DISTRICT_OFFICIAL" class="mb-2 style-padding" lg="4" md="12">
        <b-form-select class="style-filter" v-model="ward_name" :options="wards">
            <template #first>
                <b-form-select-option :value="null" disabled >Chọn Xã</b-form-select-option>
            </template>
        </b-form-select>
    </b-col>
</div>
</template>

<script>
import { ADMIN, PROVINCE_OFFICIAL, DISTRICT_OFFICIAL, WARD_OFFICIAL, PROCESS_OFFICIAL, USER } from '../../role/checkTypeRole.js'
import { httpClient } from '../../_utils/httpClient'
import { Base64 } from 'js-base64'
export default {
    name: "chart-filter",
    data() {
        return {
            province_name: null,
            district_name: null,
            ward_name: null,
            provinces: [],
            districts: [],
            wards: [],
            role: null,
            check_page: false,
            ADMIN, 
            PROVINCE_OFFICIAL, 
            DISTRICT_OFFICIAL, 
            WARD_OFFICIAL, 
            PROCESS_OFFICIAL, 
            USER,
        }
    },
    computed: {
        checkrole() {
            return this.role == ADMIN ||
            this.role==PROVINCE_OFFICIAL&&this.province_name!=null || 
            this.role==DISTRICT_OFFICIAL&&this.province_name!=null&&this.district_name!=null||
            ((this.role==WARD_OFFICIAL||this.role==PROCESS_OFFICIAL||this.role==USER)
            &&this.province_name!=null
            &&this.district_name!=null
            &&this.ward_name!=null)
        }
    },
    props: ['reset'],
    methods: {
        checkPage(){
            if(this.$route.name == 'admin.home'){
                this.check_page = false;
            }
            else {
                this.check_page = true;
            }
        },
        getRole() {
            let userData = localStorage.getItem('token').split('.')[1]
            let user = JSON.parse(Base64.decode(userData))
            if(user.role.includes('admin')) {
                this.role = 'admin'
            }
            else {
                this.role = user.role_level
            }
            if(this.role == ADMIN) {
                this.getProvinceList();
            }
            else if(this.role == PROVINCE_OFFICIAL) {
                this.province_name = user.province
            }
            else if(this.role == DISTRICT_OFFICIAL) {
                setTimeout(() => {
                this.province_name = user.province
                    setTimeout(() => {
                        this.district_name = user.district
                    }, 0);
                }, 0);
            }
            else {
                setTimeout(() => {
                    this.province_name = user.province
                    setTimeout(() => {
                        this.district_name = user.district
                        setTimeout(() => {
                            this.ward_name = user.ward
                        }, 0);
                    }, 0);
                }, 0);
            }
        },
        async getProvinceList() {
            let response = await httpClient.get(`config/provinces`)
            this.provinces = response.data.data
        },
        async getDistrictList(province_name) {
            let response = await httpClient.get(`config/districts`,{
                params: {
                    province_name
                }
            })
            this.districts = response.data.data
        },
        async getWardList(province_name,district_name) {
            let response = await httpClient.get(`config/wards`, {
                params: {
                    province_name,
                    district_name
                }
            })
            this.wards = response.data.data
        }
    },
    watch: {
        province_name() {
            this.district_name = null
            this.ward_name = null
            if(this.$route.name != 'admin.home'){
                let query = {
                    ...this.$route.query
                };
                if (this.province_name && this.role == ADMIN) {
                    query.province = this.province_name;
                } else {
                    delete(query.province);
                }
                this.$router.push({
                    name: this.$route.name,
                    query: query
                }).catch(err=>err);
            }
            else {
                if(this.checkrole){
                    this.$emit('change', this.province_name, this.district_name, this.ward_name)
                }
            }
            this.getDistrictList(this.province_name);
        },
        district_name() {
            this.ward_name = null
            if(this.$route.name != 'admin.home'){
                let query = {
                    ...this.$route.query
                };
                if (this.district_name && (this.role == PROVINCE_OFFICIAL || this.role == ADMIN)) {
                    query.district = this.district_name;
                } else {
                    delete(query.district);
                }
                this.$router.push({
                    name: this.$route.name,
                    query: query
                }).catch(err=>err);
            }
            else {
                if(this.checkrole){
                    this.$emit('change', this.province_name, this.district_name, this.ward_name)
                }
            }
            this.getWardList(this.province_name, this.district_name);
        },
        ward_name() {
            if(this.$route.name != 'admin.home'){
                let query = {
                    ...this.$route.query
                };
                if (this.ward_name && this.role != WARD_OFFICIAL && this.role != PROCESS_OFFICIAL && this.role != USER) {
                    query.ward = this.ward_name;
                } else {
                    delete(query.ward);
                }
                this.$router.push({
                    name: this.$route.name,
                    query: query
                }).catch(err=>err);
            }
            else {
                if(this.checkrole){
                    this.$emit('change', this.province_name, this.district_name, this.ward_name)
                }
            }
        },
        reset() {
            if(this.reset == true) {
                if(this.role == this.ADMIN) {
                    this.province_name = null
                    this.district_name = null
                    this.ward_name = null
                }
                else if(this.role == this.PROVINCE_OFFICIAL) {
                    this.district_name = null
                    this.ward_name = null
                }
                else if(this.role == this.DISTRICT_OFFICIAL) {
                    this.ward_name = null
                }
                else {
                    return
                }
                this.$router.query = {}
                this.$emit("reset");
            }
        }
    },
    created(){
        this.province_name = this.$route.query.province ? this.$route.query.province : null
        setTimeout(() => {
            this.district_name = this.$route.query.district ? this.$route.query.district : null
        },0)
        setTimeout(() => {
            this.ward_name = this.$route.query.ward ? this.$route.query.ward : null
        },0)
        this.getRole();
        this.checkPage()
    }
}
</script>

<style scoped>
.chart { 
    margin-top: -10px;
}
.style-filter {
    height: 41px;
    font-size: 14px;
    font-weight: 400;
    color: #74788D;
    min-width: 100px;
}
.style-padding {
    padding: 12px;
}
.style-width {
    width: calc(60% - 6px) !important;
}
@media (max-width: 991px) {
    .style-width {
        width: 100% !important;
        margin-left: 0;
    }
    .style-width .style-padding {
        padding: 0;
    }
    .style-width .style-padding{
        margin-bottom: 18px !important;
    }
}
@media (max-width: 576px) {
    .style-width .style-padding {
        padding: 0 !important;
        margin-left: 0;
        margin-bottom: 18px !important;
    }
} 
</style>
